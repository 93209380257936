import React, { useState } from 'react'
import { MdModeEditOutline } from "react-icons/md";
import AdminEditProduct from './AdminEditProduct';
import displayINRCurrency from '../helpers/displayCurrency';
import SummaryApi from '../common';

const AdminProductCard = ({
    data,
    fetchdata,
}) => {
    const [editProduct,setEditProduct] = useState(false)


    // Function to handle deletion of the product
  //   const handleDelete = async () => {
  //     try {
  //         const response = await fetch(SummaryApi.deleteProduct.url, {
  //             method: SummaryApi.deleteProduct.method,
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 // Add other necessary headers like Authorization if required
  //             },
  //             body: JSON.stringify({ productId: data._id }), // Pass the product ID to delete
  //         });

  //         const result = await response.json();

  //         if (response.ok) {
  //             // Optionally, refresh the data after deletion
  //             fetchdata();
  //         } else {
  //             console.error('Error deleting product:', result.message);
  //         }
  //     } catch (error) {
  //         console.error('Error deleting product:', error);
  //     }
  // };

    
  return (
    <div className='bg-white p-4 rounded '>
       <div className='w-40'>
            <div className='w-32 h-32 flex justify-center items-center'>
              <img src={data?.productImage[0]}  className='mx-auto object-fill h-full'/>   
            </div> 
            <h1 className='text-ellipsis line-clamp-2'>{data.productName}</h1>

            <div>

                <p className='font-semibold'>
                  {
                    displayINRCurrency(data.sellingPrice)
                  }
        
                </p>

                <div className='w-fit ml-auto p-2 bg-green-100 hover:bg-green-600 rounded-full hover:text-white cursor-pointer' onClick={()=>setEditProduct(true)}>
                    <MdModeEditOutline/>
                </div>

                  {/* Delete Button */}
                    {/* <div className='p-2 bg-red-100 hover:bg-red-600 rounded-full hover:text-white cursor-pointer' onClick={handleDelete}>
                        <MdDelete />
                    </div> */}

            </div>

          
       </div>
        
        {
          editProduct && (
            <AdminEditProduct productData={data} onClose={()=>setEditProduct(false)} fetchdata={fetchdata}/>
          )
        }
    
    </div>
  )
}

export default AdminProductCard