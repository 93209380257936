import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SummaryApi from '../common'
import { FaStar } from "react-icons/fa"
import { FaStarHalf } from "react-icons/fa"
import CategroyWiseProductDisplay from '../components/CategoryWiseProductDisplay'
import addToCart from '../helpers/addToCart'
import Context from '../context'

const ProductDetails = () => {
  const [data, setData] = useState({
    productName: "",
    brandName: "",
    category: "",
    productImage: [],
    description: "",
    price: "",
    pricee: "",
    sellingPrice: "",
    sellingPricee: "",
    offerPrice: "",
    offerPricee: "",
    quantity: "",
    quantityy: ""
  })
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [activeImage, setActiveImage] = useState("")
  const [zoomImageCoordinate, setZoomImageCoordinate] = useState({ x: 0, y: 0 })
  const [selectedOption, setSelectedOption] = useState('12-ml')
  const [zoomImage, setZoomImage] = useState(false)
  const { fetchUserAddToCart } = useContext(Context)
  const navigate = useNavigate()

  const fetchProductDetails = async () => {
    setLoading(true)
    const response = await fetch(SummaryApi.productDetails.url, {
      method: SummaryApi.productDetails.method,
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ productId: params?.id })
    })
    setLoading(false)
    const dataReponse = await response.json()
    setData(dataReponse?.data)
    setActiveImage(dataReponse?.data?.productImage[0])
  }

  useEffect(() => {
    fetchProductDetails()
  }, [params])




  const handleMouseEnterProduct = (imageURL) => setActiveImage(imageURL)
  const handleZoomImage = useCallback((e) => {
    setZoomImage(true)
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = (e.clientX - left) / width
    const y = (e.clientY - top) / height
    setZoomImageCoordinate({ x, y })
  }, [])
  const handleLeaveImageZoom = () => setZoomImage(false)

  const handleAddToCart = async (e, id) => {
    await addToCart(e, id)
    fetchUserAddToCart()
  }
  const handleBuyProduct = async (e, id) => {
    await addToCart(e, id)
    fetchUserAddToCart()
    navigate("/cart")
  }

  // Function to style the brand name with multiple colors if it has more than one word
  const renderBrandName = () => {
    if (!data.brandName) return null;
    const words = data.brandName.split(" ");
    const colors = ["text-green-600", "text-yellow-600", "text-blue-600", "text-pink-600", "text-[#6a0dad]", "text-[#ffcc99]"];
    
    return words.map((word, index) => (
      <span key={index} className={`${colors[index % colors.length]} mr-1`}>{word}</span>
    ));
  };
  
  const handleOptionClick = (option) => {
    setSelectedOption(option)
  }

  return (
    <div className='container mx-auto p-4' style={{ marginTop: '60px' }}>
      <p className='bg-blue-100 px-6 rounded-full inline-block w-fit'>{renderBrandName()}</p>
      <div className='min-h-[200px] flex flex-col lg:flex-row gap-4'>
        <div className='h-50 flex flex-col lg:flex-row-reverse gap-4'>
          <div className='h-[300px] w-[300px] lg:h-96 lg:w-96 bg-slate-200 relative p-2'>
            <img src={activeImage} className='h-full w-full object-scale-down mix-blend-multiply' onMouseMove={handleZoomImage} onMouseLeave={handleLeaveImageZoom} />
            {zoomImage && (
              <div className='hidden lg:block absolute min-w-[500px] overflow-hidden min-h-[400px] bg-slate-200 p-1 -right-[510px] top-0'>
                <div
                  className='w-full h-full min-h-[400px] min-w-[500px] mix-blend-multiply scale-150'
                  style={{
                    background: `url(${activeImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}%`
                  }}
                />
              </div>
            )}
          </div>
          {/* <div className='h-full'> */}
            {/* Image loading skeleton or image list */}
          {/* </div> */}
        </div>

        {loading ? (
          <div className='grid gap-1 w-full'>
            {/* Skeleton loaders */}
          </div>
        ) : (
          <div className='flex flex-col gap-1'>
            <h2 className='text-2xl lg:text-4xl font-medium'>{data?.productName}</h2>
            <p className='capitalize text-slate-400'>{data?.category}</p>
            <div className='text-red-600 flex items-center gap-1'>
              <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalf />
            </div>
            <div className='flex items-center gap-2 text-2xl lg:text-3xl font-medium my-1'>
            <p className='text-red-600'>
                ₹{selectedOption === '12-ml' ? data.sellingPrice : data.sellingPricee}
              </p>
              <p className='text-slate-400 line-through'>
                ₹{selectedOption === '12-ml' ? data.price : data.pricee}
              </p>
              <p className='text-green-400'>
                Off: ₹{selectedOption === '12-ml' ? data.offerPrice : data.offerPricee}
              </p>
              {/* <p className='text-red-600'>₹{(data.sellingPrice)}</p>
              <p className='text-slate-400 line-through'>₹{(data.price)}</p>
              <p className='text-green-400'>Off: ₹{(data.offerPrice)}</p> */}
            </div>

            <div>
              <p className='text-slate-600 font-medium my-1'>Description : {data?.description}</p>
            </div>
            {/* end main data */}

            <div className='flex items-center gap-3 my-2'>
              <button className='border-2 border-red-600 rounded px-3 py-1 min-w-[120px] text-red-600 font-medium hover:bg-red-600 hover:text-white' onClick={(e) => handleBuyProduct(e, data?._id)}>Buy</button>
              <button className='border-2 border-red-600 rounded px-3 py-1 min-w-[120px] font-medium text-white bg-red-600 hover:text-red-600 hover:bg-white' onClick={(e) => handleAddToCart(e, data?._id)}>Add To Cart</button>
            </div>

            {/* Small Cards Section */}
  <h1 className='text-1xl font-semibold py-2'>Options 👇</h1>
<div className="grid grid-cols-2 gap-4 mt-2"> 

   {/* 12-ml Cards */}
   <div
                className={`border rounded shadow-sm p-2 flex flex-col items-center ${
                  selectedOption === '12-ml' ? 'bg-yellow-200' : 'bg-blue-100'
                } cursor-pointer`}
                onClick={() => handleOptionClick('12-ml')}
              >
    <img
                  src={data?.productImage[1] || activeImage}
                  alt="12-ml Product"
                  className="w-16 h-16 object-cover mb-2"
                />
    <p className="text-center text-xx text-gray-600">{data?.productName}-{(data.quantityy)} ml</p> {/* Smaller text */}
    <div className="flex items-center gap-2">
  <p className="text-red-600">₹{data.sellingPrice}</p>
  <p className="text-slate-400 line-through">₹{data.price}</p>
</div>
    <p className='text-green-400'>Off: ₹{(data.offerPrice)}</p>
  </div>
  

   {/* 6-ml Cards */}
   <div
                className={`border rounded shadow-sm p-2 flex flex-col items-center ${
                  selectedOption === '6-ml' ? 'bg-yellow-200' : 'bg-blue-100'
                } cursor-pointer`}
                onClick={() => handleOptionClick('6-ml')}
              >
                <img
                  src={data?.productImage[2] || activeImage}
                  alt="6-ml Product"
                  className="w-16 h-16 object-cover mb-2"
                />
    <p className="text-center text-xx text-gray-600">{data?.productName}-{(data.quantity)} ml</p> {/* Smaller text */}
    <div className="flex items-center gap-2">
    <p className='text-red-600'>₹{(data.sellingPricee)}</p>
    <p className='text-slate-400 line-through'>₹{(data.pricee)}</p>
  </div>
    <p className='text-green-400'>Off: ₹{(data.offerPricee)}</p>
  </div>
</div>
           
          </div>
        )}

        
      </div>

  

    
      {data.category && (
        <CategroyWiseProductDisplay category={data?.category} heading={"Recommended Product👇"} />
      )}
    </div>
  )
}

export default ProductDetails














// import React, { useCallback, useContext, useEffect, useState } from 'react'
// import { useNavigate, useParams } from 'react-router-dom'
// import SummaryApi from '../common'
// import { FaStar } from "react-icons/fa"
// import { FaStarHalf } from "react-icons/fa"
// import displayINRCurrency from '../helpers/displayCurrency'
// import VerticalCardProduct from '../components/VerticalCardProduct'
// import CategroyWiseProductDisplay from '../components/CategoryWiseProductDisplay'
// import addToCart from '../helpers/addToCart'
// import Context from '../context'

// const ProductDetails = () => {
//   const [data, setData] = useState({
//     productName: "",
//     brandName: "",
//     category: "",
//     productImage: [],
//     description: "",
//     price: "",
//     pricee: "",
//     sellingPrice: "",
//     sellingPricee: "",
//     offerPrice: "",
//     offerPricee: "",
//     quantity: "",
//     quantityy: ""
//   })
//   const params = useParams()
//   const [loading, setLoading] = useState(true)
//   const [activeImage, setActiveImage] = useState("")
//   const [zoomImageCoordinate, setZoomImageCoordinate] = useState({ x: 0, y: 0 })
//   const [zoomImage, setZoomImage] = useState(false)
//   const { fetchUserAddToCart } = useContext(Context)
//   const navigate = useNavigate()

//   const fetchProductDetails = async () => {
//     setLoading(true)
//     const response = await fetch(SummaryApi.productDetails.url, {
//       method: SummaryApi.productDetails.method,
//       headers: { "content-type": "application/json" },
//       body: JSON.stringify({ productId: params?.id })
//     })
//     setLoading(false)
//     const dataReponse = await response.json()
//     setData(dataReponse?.data)
//     setActiveImage(dataReponse?.data?.productImage[0])
//   }

//   useEffect(() => {
//     fetchProductDetails()
//   }, [params])

//   const handleMouseEnterProduct = (imageURL) => setActiveImage(imageURL)
//   const handleZoomImage = useCallback((e) => {
//     setZoomImage(true)
//     const { left, top, width, height } = e.target.getBoundingClientRect()
//     const x = (e.clientX - left) / width
//     const y = (e.clientY - top) / height
//     setZoomImageCoordinate({ x, y })
//   }, [])
//   const handleLeaveImageZoom = () => setZoomImage(false)
//   const handleAddToCart = async (e, id) => {
//     await addToCart(e, id)
//     fetchUserAddToCart()
//   }
//   const handleBuyProduct = async (e, id) => {
//     await addToCart(e, id)
//     fetchUserAddToCart()
//     navigate("/cart")
//   }

//   // Function to style the brand name with multiple colors if it has more than one word
//   const renderBrandName = () => {
//     if (!data.brandName) return null;
//     const words = data.brandName.split(" ");
//     const colors = ["text-green-600", "text-yellow-600", "text-blue-600", "text-pink-600", "text-[#6a0dad]", "text-[#ffcc99]"];
    
//     return words.map((word, index) => (
//       <span key={index} className={`${colors[index % colors.length]} mr-1`}>{word}</span>
//     ));
//   };
  

//   return (
//     <div className='container mx-auto p-4' style={{ marginTop: '60px' }}>
//       <p className='bg-blue-100 px-6 rounded-full inline-block w-fit'>{renderBrandName()}</p>
//       <div className='min-h-[200px] flex flex-col lg:flex-row gap-4'>
//         <div className='h-50 flex flex-col lg:flex-row-reverse gap-4'>
//           <div className='h-[300px] w-[300px] lg:h-96 lg:w-96 bg-slate-200 relative p-2'>
//             <img src={activeImage} className='h-full w-full object-scale-down mix-blend-multiply' onMouseMove={handleZoomImage} onMouseLeave={handleLeaveImageZoom} />
//             {zoomImage && (
//               <div className='hidden lg:block absolute min-w-[500px] overflow-hidden min-h-[400px] bg-slate-200 p-1 -right-[510px] top-0'>
//                 <div
//                   className='w-full h-full min-h-[400px] min-w-[500px] mix-blend-multiply scale-150'
//                   style={{
//                     background: `url(${activeImage})`,
//                     backgroundRepeat: 'no-repeat',
//                     backgroundPosition: `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}%`
//                   }}
//                 />
//               </div>
//             )}
//           </div>
//           {/* <div className='h-full'> */}
//             {/* Image loading skeleton or image list */}
//           {/* </div> */}
//         </div>

//         {loading ? (
//           <div className='grid gap-1 w-full'>
//             {/* Skeleton loaders */}
//           </div>
//         ) : (
//           <div className='flex flex-col gap-1'>
            
//             <h2 className='text-2xl lg:text-4xl font-medium'>{data?.productName}</h2>
//             <p className='capitalize text-slate-400'>{data?.category}</p>
//             <div className='text-red-600 flex items-center gap-1'>
//               <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalf />
//             </div>
//             <div className='flex items-center gap-2 text-2xl lg:text-3xl font-medium my-1'>
//               <p className='text-red-600'>₹{(data.sellingPrice)}</p>
//               <p className='text-slate-400 line-through'>₹{(data.price)}</p>
//               <p className='text-green-400'>Off: ₹{(data.offerPrice)}</p>
//             </div>

//             <div>
//               <p className='text-slate-600 font-medium my-1'>Description :</p>
//               <p>{data?.description}</p>
//             </div>
//             <div className='flex items-center gap-3 my-2'>
//               <button className='border-2 border-red-600 rounded px-3 py-1 min-w-[120px] text-red-600 font-medium hover:bg-red-600 hover:text-white' onClick={(e) => handleBuyProduct(e, data?._id)}>Buy</button>
//               <button className='border-2 border-red-600 rounded px-3 py-1 min-w-[120px] font-medium text-white bg-red-600 hover:text-red-600 hover:bg-white' onClick={(e) => handleAddToCart(e, data?._id)}>Add To Cart</button>
//             </div>

//             {/* Small Cards Section */}
//   <h1 className='text-1xl font-semibold py-2'>Options 👇</h1>
// <div className="grid grid-cols-2 gap-4 mt-2"> 

//    {/* 12-ml Cards */}
//   <div className="border rounded shadow-sm p-2 flex flex-col items-center bg-blue-100">
//     <img
//       src={data?.productImage[1] || activeImage}
//       alt="Product 1"
//       className="w-16 h-16 object-cover mb-2" // Reduced size
//     />
//     <p className="text-center text-xx text-gray-600">{data?.productName}-{(data.quantityy)} ml</p> {/* Smaller text */}
//     <div className="flex items-center gap-2">
//   <p className="text-red-600">₹{data.sellingPrice}</p>
//   <p className="text-slate-400 line-through">₹{data.price}</p>
// </div>
//     <p className='text-green-400'>Off: ₹{(data.offerPrice)}</p>
//   </div>
  

//    {/* 6-ml Cards */}
//   <div className="border rounded shadow-sm p-2 flex flex-col items-center bg-blue-100">
//     <img
//       src={data?.productImage[2] || activeImage}
//       alt="Product 2"
//       className="w-16 h-16 object-cover mb-2" // Reduced size
//     />
//     <p className="text-center text-xx text-gray-600">{data?.productName}-{(data.quantity)} ml</p> {/* Smaller text */}
//     <div className="flex items-center gap-2">
//     <p className='text-red-600'>₹{(data.sellingPricee)}</p>
//     <p className='text-slate-400 line-through'>₹{(data.pricee)}</p>
//   </div>
//     <p className='text-green-400'>Off: ₹{(data.offerPricee)}</p>
//   </div>
// </div>
           
//           </div>
//         )}

        
//       </div>

  

    
//       {data.category && (
//         <CategroyWiseProductDisplay category={data?.category} heading={"Recommended Product👇"} />
//       )}
//     </div>
//   )
// }

// export default ProductDetails