import React, { useEffect, useState } from 'react'
import image1 from '../assest/banner/img4.jpg'
import image2 from '../assest/banner/img3.jpg'
import image3 from '../assest/banner/img4.jpg'
import image4 from '../assest/banner/img3.jpg'
import image5 from '../assest/banner/img4.jpg'


import image1Mobile from '../assest/banner/img4_mobile.jpg'
import image2Mobile from '../assest/banner/img3_mobile.jpg'
import image3Mobile from '../assest/banner/img4_mobile.jpg'
import image4Mobile from '../assest/banner/img3_mobile.jpg'
import image5Mobile from '../assest/banner/img4_mobile.jpg'

import { FaAngleRight } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";


const BannerProduct = () => {
    const [currentImage,setCurrentImage] = useState(0)

    const desktopImages = [
        image1,
        image2,
        image3,
        image4,
        image5
    ]

    const mobileImages = [
        image1Mobile,
        image2Mobile,
        image3Mobile,
        image4Mobile,
        image5Mobile
    ]

    const nextImage = () =>{
        if(desktopImages.length - 1 > currentImage){
            setCurrentImage(preve => preve + 1)
        }
    }

    const preveImage = () =>{
        if(currentImage != 0){
            setCurrentImage(preve => preve - 1)
        }
    }


    useEffect(()=>{
        const interval = setInterval(()=>{
            if(desktopImages.length - 1 > currentImage){
                nextImage()
            }else{
                setCurrentImage(0)
            }
        },5000)

        return ()=> clearInterval(interval)
    },[currentImage])

  return (
    <div className='container mx-auto px-4 rounded '>
        <div className='h-56 md:h-72 w-full bg-slate-200 relative'>

                <div className='absolute z-10 h-full w-full md:flex items-center hidden '>
                    <div className=' flex justify-between w-full text-2xl'>
                        <button onClick={preveImage} className='bg-white shadow-md rounded-full p-1'><FaAngleLeft/></button>
                        <button onClick={nextImage} className='bg-white shadow-md rounded-full p-1'><FaAngleRight/></button> 
                    </div>
                </div>

                {/**desktop and tablet version */}
              <div className='hidden md:flex h-full w-full overflow-hidden'>
                {
                        desktopImages.map((imageURl,index)=>{
                            return(
                            <div className='w-full h-full min-w-full min-h-full transition-all' key={imageURl} style={{transform : `translateX(-${currentImage * 100}%)`}}>
                                <img src={imageURl} className='w-full h-full'/>
                            </div>
                            )
                        })
                }
              </div>


                {/**mobile version */}
                <div className='flex h-full w-full overflow-hidden md:hidden'>
                {
                        mobileImages.map((imageURl,index)=>{
                            return(
                            <div className='w-full h-full min-w-full min-h-full transition-all' key={imageURl} style={{transform : `translateX(-${currentImage * 100}%)`}}>
                                <img src={imageURl} className='w-full h-full object-cover'/>
                            </div>
                            )
                        })
                }
              </div>


        </div>
    </div>
  )
}

export default BannerProduct












// import React, { useEffect, useState } from 'react';
// import SummaryApi from '../common';
// import ImageUpload from '../components/ImageUpload';

// const BannerProduct = () => {
//   const [images, setImages] = useState(null);

//   useEffect(() => {
//     fetchImages();
//   }, []);

//   const fetchImages = async () => {
//     try {
//       const response = await fetch(SummaryApi.getImage.url);
//       const data = await response.json();
//       setImages(data);
//     } catch (error) {
//       console.error('Error fetching images:', error);
//     }
//   };

//   const handleUploadSuccess = (uploadedImages) => {
//     console.log('Uploaded images:', uploadedImages);
//     setImages(uploadedImages);
//   };

//   return (
//     <div className="container mx-auto px-4 rounded">
//          <ImageUpload onUploadSuccess={handleUploadSuccess} />
//       {images && (
//         <div className="h-56 md:h-72 w-full bg-slate-200 relative">
//           {/* Desktop and tablet version */}
//           <div className="hidden md:flex h-full w-full overflow-hidden">
//             {(images.desktopImages || []).map((img, idx) => (
//               <div key={idx} className="w-full h-full min-w-full min-h-full transition-all">
//                 <img src={`/${img}`} alt={`Desktop ${idx}`} className="w-full h-full" />
//               </div>
//             ))}
//           </div>

//           {/* Mobile version */}
//           <div className="flex h-full w-full overflow-hidden md:hidden">
//             {(images.mobileImages || []).map((img, idx) => (
//               <div key={idx} className="w-full h-full min-w-full min-h-full transition-all">
//                 <img src={`/${img}`} alt={`Mobile ${idx}`} className="w-full h-full object-cover" />
//               </div>
//             ))}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default BannerProduct;